import React from 'react';
import { Wrapper } from '@components/Wrapper';
import { ContactModal } from '@components/ContactModal';

import styles from './style.module.scss';

export const GetStarted = () => (
  <Wrapper>
    <div className={styles.root}>
      <div className={styles.container}>
        <h3 className={styles.title}>Get Started</h3>
        <hr className={styles.line} />
        <div className={styles.content}>
          Professional analysis and expert advice you can rely on. We&lsquo;ll find the best way to make your solution
          efficient and offer cost-effective alternatives.
          <div className={styles.btnWrapper}>
            <ContactModal />
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
);
