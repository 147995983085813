import React from 'react';
import { SEO } from '@components/SEO';
import { PageBlock } from '@components/PageBlock';
import { GetStarted } from '@components/GetStarted';
import { Strategy } from '@components/Strategy';
import { PageContainer } from '@components/PageContainer';

const CompetenceCenter = () => (
  <PageContainer isSideMenuPage>
    <SEO title="Competence Center" />
    <div>
      <PageBlock
        title="Competence Center"
        titleContent={
          <span>
            Being an acknowledged expert in Blockchain and Cloud Services means both learning and teaching on a daily
            basis. Not only do
            <span className="text-orange"> we solve business puzzles for our clients,</span> but also become long-term
            expertise partners. Based on your needs, we choose the strategy that matches the case, corresponds with
            resources and hits the bullseye.
          </span>
        }
        content={
          <>
            <span>
              All our in-house specialists are constantly growing to be ready for the most complicated challenges.
              However, being on a constant lookout for new talents, we are never limited by existing resources.
              <br /> <br />
              We cooperate with <span className="text-orange">the best technology institutes*</span> which helps our
              recruiters to form teams upon request. Once we know what result you need, we know which specialists can
              get it done and where to find them. Not only do we find, but also train them to make sure the quality
              level is compliant with both our company and your project requirements.
            </span>
            <br /> <br />
            <span className="text-lightGray">
              * Igor Sikorsky Kyiv Polytechnic Institute, National Technical University “Kharkiv Polytechnic Institute”,
              Taras Shevchenko National University of Kyiv.
            </span>
          </>
        }
      />
      <Strategy />
      <GetStarted />
    </div>
  </PageContainer>
);

export default CompetenceCenter;
