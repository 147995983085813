import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Wrapper } from '@components/Wrapper';
import Arrow from '@images/arrow.svg';

import styles from './style.module.scss';

export const PageBlock = ({ title, titleContent, image, content }) => (
  <Wrapper>
    <div className={styles.root}>
      <div>
        <Link to="/" className={styles.link}>
          <img className={styles.back} alt="back to home" src={Arrow} />
          Back to the Home
        </Link>
        <div className={styles.contentWrapper}>
          <div className={styles.lgFlex}>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.mainContent}>{titleContent}</div>
          </div>
          <hr className={styles.line} />
          <div className={styles.lgFlex}>
            {image && (
              <div className={styles.imageWrapper}>
                <img className={styles.image} alt="competence center" src={image} />
              </div>
            )}
            <div className={styles.content}>{content}</div>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
);

PageBlock.propTypes = {
  title: PropTypes.string,
  titleContent: PropTypes.node,
  image: PropTypes.string,
  content: PropTypes.node
};
