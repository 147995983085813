import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

export const SocialIcon = memo(({ color, title, href }) => (
  <div className={styles.root} style={{ borderColor: color }}>
    <a href={href} target="_blank" className={styles.text} style={{ color }} rel="noopener noreferrer">
      {title}
    </a>
  </div>
));

SocialIcon.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};

SocialIcon.defaultProps = {
  color: '#E35F41'
};

SocialIcon.displayName = 'SocialIcon';
