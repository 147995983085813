import React from 'react';

import { SocialIcon } from '@components/SocialIcon';
import { Wrapper } from '@components/Wrapper';
import styles from './style.module.scss';

export const Strategy = () => (
  <Wrapper>
    <h2 className={styles.header}>Turning Your Story into a Unique Strategy</h2>
    <hr className={styles.line} />
    <div className={styles.container}>
      <div className={styles.item}>
        <img alt="Elena Shostak" className={styles.itemIcon} />
        <div>
          <h3 className={styles.h3}>Elena Shostak</h3>
          <h5 className={styles.subTitle}>Chief Executive Officer</h5>
          <p className={styles.content}>
            Elena is an expert in organisational management and leadership, and a business strategist. For more than ten
            years, she has hold top positions in business management and development in major international
            corporations. She is a consultant and business advisor for technology startups. Elena's key task is to build
            and develop a successful business, create centers of unique technical expertise, and develop international
            markets.
          </p>
          <div className="flex">
            <SocialIcon title="In" href="https://www.linkedin.com/in/elena-shostak-3a558a24/" />
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <img alt="Oleksii Koshlatyi" className={styles.itemIcon} />
        <div>
          <h3 className={styles.h3}>Oleksii Koshlatyi</h3>
          <h5 className={styles.subTitle}>Chief Technical Officer</h5>
          <p className={styles.content}>
            Oleksii is an experienced executive who provides sound technical leadership in all aspects of different
            businesses. He is recognized for his success in implementing strategies to drive the growth of various
            competencies. Being well-versed in today's technological trends Oleksii is responsible for outlining the
            holding’s technological vision, ensuring technical teams are aligned with the business needs, developing and
            implementing technology strategies.
          </p>
          <div className="flex">
            <SocialIcon title="In" href="https://www.linkedin.com/in/oleksiikoshlatyi/" />
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
);
